import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { IoMdMenu } from "react-icons/io";
import { MdClose } from "react-icons/md";
import logoWhite from "../images/patagoniaWhite.png"
import logoDark from "../images/patagoniaDark.png"
import logoDarkLight from "../images/patagoniaDarkLight.png"
import Menu from "./Menu"
import MenuMobile from "./MenuMobile"


const HeaderHL = ({ isScrolling, onMenuToggle, isDark, menuOpen, width }) => (
  <header
    className={'header'}
    style={{
      background: `transparent`,
      marginBottom: `1.45rem`,
      position: 'fixed',
      width: '100vw',
      zIndex: '10',
      opacity: isScrolling ? 0 : 1,
      dark: false,
    }}
  >
    <div
      className={'headerWrapper headerHL'}
    >

      {!menuOpen && <IoMdMenu onClick={onMenuToggle} className={'hamburgerMenuIcon'}/>}

      {
        width > 767 ?
          menuOpen && <MdClose onClick={onMenuToggle} className={'hamburgerMenuIcon xIcon'}/>
          :
          <span></span>
      }
      {/*<Media>
        {({ breakpoints, currentBreakpoint }) =>
          breakpoints[currentBreakpoint] > breakpoints.tablet ? (
            menuOpen && <MdClose onClick={onMenuToggle} className={'hamburgerMenuIcon xIcon'}/>
          ) : (
            <span></span>
          )
        }
      </Media>*/}
      <Link to={'/'}>
        {
          width > 767 ?
            <img className={'headerLogo'} alt={'logo'} src={isDark ? logoDark : logoWhite}/>
            :
            <img className={'headerLogo'} alt={'logo'} src={isDark ? logoDarkLight : logoWhite}/>
        }
      </Link>


      {/*<Media>
        {({ breakpoints, currentBreakpoint }) =>
          breakpoints[currentBreakpoint] > breakpoints.tablet ? (
            <img className={'headerLogo'} alt={'logo'} src={isDark ? logoDark : logoWhite}/>
          ) : (
            <img className={'headerLogo'} alt={'logo'} src={isDark ? logoDarkLight : logoWhite}/>
          )
        }
      </Media>*/}

      {/* <h1 style={{ margin: 0 }}>1
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>*/}
    </div>
  </header>
)

HeaderHL.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderHL.defaultProps = {
  siteTitle: ``,
}

export default  HeaderHL
