import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import fbc from "../images/bcFirstDivider.png"
import sbc from "../images/bcSecondDivider.png"
import goBack from '../images/beerDetail/goBack.png'
import amberlager from '../images/beerImages/Amber.jpg'
import bohemian from '../images/beerImages/Bohemian.jpg'
import fipa from '../images/beerImages/Fipa.jpg'
import KM from '../images/beerImages/KM.jpg'
import kune from '../images/beerImages/Kune.jpg'
import porter from '../images/beerImages/Porter.jpg'
import octubrefest from '../images/beerImages/Octubrefest.jpg'
import weisse from '../images/beerImages/Weisse.jpg'
import ado from '../images/beerImages/ADO.jpg'
import hoppylagger from '../images/beerImages/HL.jpg'
import veraipa from '../images/beerImages/VL.jpg'
import Cookies from "js-cookie"
import SEO from "../components/seo"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import Footer from "../components/Footer"
import BirthConsent from "../components/BirthConsent"
import Header from "../components/header"
import HeaderHL from "../components/HeaderHL"
import comunal from '../images/comunal.jpg'
import lupuloMenu from '../images/lupulo-verticalmenu.png'
const hasConfirmed = () => {
    return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
        || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};
export default class DetailHL extends React.Component {
    static propTypes = {
        user: PropTypes.object,
    }
    constructor(props) {
        super(props)
        this.state = {
            amberlager: amberlager,
            bohemianpilsener: bohemian,
            fipa: fipa,
            km: KM,
            kune: kune,
            porter: porter,
            octubrefest: octubrefest,
            weisse: weisse,
            abrazodeoso: ado,
            hoppylagger: hoppylagger,
            veraipa: veraipa,
            isScrolling: false,
            menuOpen: false,
            menuClass: 'slideOut',
            isDark: true,
            width: 1000,
            birthConsent: true,
        }
    }
    componentDidMount() {
        window.addEventListener("scroll", this.onScroll);
        this.setState({
            width: window.innerWidth,
        })
        if (hasConfirmed()) {
            this.handleConsent()
        }
        else {
            this.setState({
                birthConsent: false,
            })
        }
    }
    handleConsent = () => {
        this.setState({
            birthConsent: true,
        })
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }
    onMenuToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen,
            menuClass: this.state.menuOpen ? 'slideOut' : 'slideIn',
            isDark: !this.state.menuOpen
        })
    };
    onScroll = () => {
        this.setState({ isScrolling: true });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({ isScrolling: false });
        }, 200);
        var section = document.getElementsByTagName("SECTION");
        var bScroll = document.scrollingElement.scrollTop;
        for (var i = 0; i < section.length; i++) {
            var sHeight = section[i].offsetHeight;
            var offsets = section[i].offsetTop;
            if (bScroll > offsets && bScroll < offsets + sHeight) {
                console.log(section[i].className)
                if (section[i].className !== 'holaHome') {
                    this.setState({ isDark: true });
                }
                else if (section[i].className === 'holaHome') {
                    this.setState({ isDark: false });
                }
            }/* else {
        section[i].className = "section";
      }*/
        }
    };
    render() {
        console.log(this.props)
        let gin = this.props.pageContext
        return (
            <div>
                <SEO title="Nuestras Cervezas" />
                {this.state.birthConsent &&
                    <div>
                        <Header isScrolling={this.state.isScrolling} onMenuToggle={this.onMenuToggle} isDark={this.state.isDark}
                            menuOpen={this.state.menuOpen} width={this.state.width} />
                        <Link to={'/nuestrascervezas'} className={'goBackDesktop'}>
                            <img  alt="" src={goBack} className={'goBack'} />
                        </Link>
                        <div className={'breadcrumbsContainer breadcrumbsDetailBeer'}>
                            <div className={'breadcrumbsWrapper'}>
                                <span className={'bcSection'}>gin</span>
                                <img  alt="" className={'sbcGin'} src={sbc} />
                                <span className={'bcPage'}>{gin.title}</span>
                                <img  alt="" className={'sbcGin'} src={sbc} />
                                <img  alt="" className={'bcLupulo'} src={lupuloMenu} />
                            </div>
                        </div>
                        <img  alt="" className={'beerDetailsImage'} src={comunal} />
                        <div className={'beerDetailContent'}>
                            <div className={'beerDetailsContainer'}>
                                <div className={'beerDetailsTitle'}>{gin.variedad1.title}</div>
                                <div className={'beerDetails'}>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            APARIENCIA
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad1.apariencia}</div>
                                    </div>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            AROMA
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad1.aroma}</div>
                                    </div>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            SABOR
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad1.sabor}</div>
                                    </div>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            CERVEZA<br />DE ORIGEN
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad1.cervezaOrigen}</div>
                                    </div>
                                </div>
                                {/* ////////////////////   GIN CON SAUCO   /////////////////////// */}
                                <div className={'saucoDetailsTitle'}>{gin.variedad2.title}</div>
                                <div className={'beerDetails'}>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            APARIENCIA
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad2.apariencia}</div>
                                    </div>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            AROMA
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad2.aroma}</div>
                                    </div>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            SABOR
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad2.sabor}</div>
                                    </div>
                                    <div className={'beerDetailItem'}>
                                        <div className={'beerDetailItemFeature'}>
                                            CERVEZA<br />DE ORIGEN
                                        </div>
                                        <div className={'beerDetailItemData'}>{gin.variedad2.cervezaOrigen}</div>
                                    </div>
                                </div>
                                <Link to={'/nuestrascervezas'} className={'goBackMobile'}>
                                    <img  alt="" src={goBack} className={'goBack'} />
                                </Link>
                                <div className={"ginBuyButtom"}>
                                    <a href={'https://www.craftsociety.com.ar/pages/gin-comunal'} className={'beerBuy'}>
                                        COMPRAR</a>
                                    <div className={'ginDownload'}>descargar ficha</div>
                                </div>
                            </div>
                            <div className={'ginDetailsImageContainer'}>
                            </div>
                        </div>
                        {
                            this.state.width > 767 ?
                                <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen} />
                                :
                                <MenuMobile onMenuToggle={this.onMenuToggle} menuClass={this.state.menuClass} menuOpen={this.state.menuOpen} />
                        }
                        <div className={"historiaContainer"}>
                            <div className={'breadcrumbsContainer breadcrumbsDetailBeer breadcrumbsHistoria'}>
                                <div className={'breadcrumbsWrapper'}>
                                    <img  alt="" className={'fbc'} src={fbc} />
                                    <span className={'bcPage'}>historia y proceso</span>
                                    <img  alt="" className={'fbc'} src={fbc} />
                                </div>
                            </div>
                            <div className={"textGinContainer"}>
                                <div className={"textHistoria"}>
                                    <div className={"titleHistoria"}>Historia</div>
                                    {gin.historia[0]}<br />{gin.historia[1]}
                                </div>
                                <div className={"textProceso"}>
                                    <div className={"titleHistoria"}>Proceso</div>
                                    {gin.proceso}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div >
                }
                {
                    !this.state.birthConsent &&
                    <BirthConsent handleConsent={this.handleConsent} />
                }
            </div >
        )
    }
}

export const query = graphql`
query($path: String!)
{
  allSitePage(filter: { path: { eq: $path }}) {
    edges {
     node {
       context{
         route
         title
         apariencia
         aroma
         sabor
         temperatura
         vaso
         maltas
         lupulos
         maridaje
         extra
         image
         menuImage
         description
         abv
         ibu
         calibre1
         calibre2
         calibre3
         calibre4

        }
      }
    }
  }
}`